import axios from '../api/axios';
import useAuth from './useAuth';
import tokenService from "../components/Util/tokenService";

const useRefreshToken = () => {
    const {auth, setAuth} = useAuth();

    return async () => {
        const response = await axios.post('/api/auth/v1/refreshToken', {
            'refreshToken': auth.refreshToken
        });
        tokenService.save(response.data)
        setAuth(prev => {
            return {...prev, accessToken: response.data.accessToken}
        });
        return response.data.accessToken;
    };
};

export default useRefreshToken;