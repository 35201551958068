import Modal from "react-modal";
import React, {useState} from "react";
import styles from "../../css/ProjectsBlock.module.css";
import {useForm} from "react-hook-form";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import {useNavigate} from "react-router-dom";

const customStyles = {
    content: {
        width: '628px',
        height: '360px',
        borderRadius: window.outerWidth <= 480 ? '0' : '16px',
        padding: window.outerWidth <= 480 ? '16px' : '32px',
        margin: window.outerWidth <= 480 ? '0px' : 'auto',
        overflow: 'hidden',
        inset: window.outerWidth <= 480 ? '0' : '40px',
    },
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
};

const premisesTypes = [
    {
        key: "LIVING_SPACE", value: "Жилое помещение"
    },
    {
        key: "COMMERCE", value: "Коммерческое помещение"
    }
]

function ProjectInfoModal({project, modalIsOpen, setIsOpen, newProjectAdded, setNewProjectAdded}) {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const {
        register, control, setError, setValue,
        handleSubmit, formState
    }
        = useForm({
        mode: 'onChange'
    });
    const {errors} = formState;

    function closeModal() {
        setIsOpen(false);
    }

    function afterOpenModal() {
        if (project) {
            // console.log(room);
            setValue('name', project.name);
            setValue('premisesType', project.premisesType.key);
            setValue("squareMeters", project.squareMeters);
        }
    }

    const postProject = async (data) => {
        const response = await axiosPrivate.post(`/api/partner/v1/projects`, data);
        return response.data;
    }

    const putProject = async (data) => {
        const response
            = await axiosPrivate.put(`/api/partner/v1/projects/${project.id}`, data);
        return response.data;
    }

    function submit(data) {
        const response = project ? putProject(data) : postProject(data);

        response.then(data => {
            console.log(data);
            setNewProjectAdded(!newProjectAdded);
            setIsOpen(false);
        }).catch(error => {
            if (error.response) {
                alert(error.response.data.message);
            }
        });
    }

    const deleteProject = () => {
        const userConfirmed = window.confirm("Вы уверены, что хотите удалить проект?");
        if (!userConfirmed) {
            return;
        }

        const response = axiosPrivate.delete(`/api/partner/v1/projects/${project.id}`);
        response.then(() => {
            // redirect to projects page
            navigate('/partner/profile');
        }).catch(error => {
            if (error.response) {
                alert(error.response.data.message);
            }
        });
    }

    return (
        <div className={styles.addProjectModalContainer}>
            <Modal isOpen={modalIsOpen}
                   onAfterOpen={afterOpenModal}
                   onRequestClose={closeModal}
                   style={customStyles}
                   appElement={document.getElementById('root')}
                   contentLabel={'Добавить проект'}>
                <div>
                    <h2>
                        {
                            project &&
                            'Редактировать проект'
                        }
                        {
                            !project &&
                            'Добавить проект'
                        }
                    </h2>

                    <form onSubmit={handleSubmit(submit)}>

                        <div className={styles.formControl}>
                            <input id='name' type='text' placeholder={'Название проекта'}
                                   {...register('name', {
                                       required:
                                           'Название проекта не может быть пустым'
                                   })} />
                            <p className={styles.error}> {errors.name?.message}</p>
                        </div>

                        <div className={styles.formControl}>
                            <select id={'premisesType'} {...register('premisesType', {
                                    required: 'Выберите тип помещения',
                                })}>
                                {premisesTypes.map((premisesType) => {
                                    return (
                                        <option key={premisesType.key} value={premisesType.key}>
                                            {premisesType.value}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div className={styles.formControl}>
                            <input id={'squareMeters'} type={'number'} placeholder={'Общая площадь'}
                                   {...register('squareMeters', {
                                       required:
                                           'Укажите общую площадь'
                                   })}/>
                            <p className={styles.error}> {errors.squareMeters?.message}</p>
                        </div>

                        {
                            !project &&
                            <button className={styles.addButton} type={'submit'}>
                                Создать
                            </button>
                        }

                        {
                            project &&
                            <div style={{
                                float: 'right'
                            }}>
                                <button className={styles.deleteButton}
                                        type={'button'}
                                        onClick={deleteProject}>
                                    Удалить
                                </button>

                                <button className={styles.addButton} type={'submit'}>
                                    Редактировать
                                </button>
                            </div>
                        }
                    </form>

                </div>

            </Modal>
        </div>
    );
}

export default ProjectInfoModal;
