import React, {useEffect} from 'react';
import styles from './Login.module.css';
import {useForm} from 'react-hook-form'
import {DevTool} from '@hookform/devtools'
import authService from "../../api/authService";
import useAuth from '../../hooks/useAuth'
import {Link, useNavigate} from "react-router-dom";
import tokenService from "../Util/tokenService";

function Login() {
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();
    const {
        register, control, setError,
        handleSubmit, formState
    }
        = useForm({
        mode: 'onChange'
    });

    useEffect(() => {
        // if user is auth
        if (auth) {
            navigate('/partner/profile');
        }
    }, []);

    const {errors} = formState;

    const login = (data) => {
        const response = authService.login(data.email, data.password);

        response.then(data => {
            tokenService.save(data);
            setAuth(data);

            navigate('/partner/profile');
        }).catch(error => {
            if (error.response) {
                setError('http', {
                    type: 'manual',
                    message: error.response.data.message
                })
            }
        });
    }

    return (
        <div className={styles.loginContainer}>
            <h1 className={styles.title}>
                ВХОД В АККАУНТ
            </h1>

            <form onSubmit={handleSubmit(login)} noValidate>

                <p className={styles.httpError}>
                    {errors.http?.message}
                </p>

                {/* Email */}
                <div className={styles.formControl}>
                    <input id='email' type='email' placeholder={'Логин'}
                           {...register('email', {
                               required:
                                   'Email не может быть пустым'
                           })} />
                    <p className={styles.error}> {errors.email?.message}</p>
                </div>

                {/* Password */}
                <div className={styles.formControl}>
                    <input id='password' type='password' placeholder={'Пароль'}
                           {...register('password', {
                               required: 'Введите пароль'
                           })} />
                    <p className={styles.error}> {errors.password?.message}</p>
                </div>

                <button type='submit'>
                    Войти
                </button>
            </form>

            <Link to='/register' className={styles.link}>
                Регистрация
            </Link>

            <DevTool control={control}/>
        </div>
    )
}

export default Login;