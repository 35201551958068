import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import styles from './Navbar.module.css';
import logo from './abdiz-logo.svg';
import avaPlaceholder from "../PartnerProfile/icons/ava_placeholder.png";
import DropDownProfile from "../Login/DropDownProfile";

function Navbar({auth, profile}) {
    const [openProfile, setOpenProfile] = useState(false);
    const dropdownRef = useRef(null);
    const logoRef = useRef(null);

    const handleClickOutside = (event) => {
        if (!dropdownRef) {
            setOpenProfile(false);
        }

        if (dropdownRef && dropdownRef.current
            && !dropdownRef.current.contains(event.target)
            && !logoRef.current.contains(event.target)) {
            setOpenProfile(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <nav>
                <header className={styles.mainHeader}>
                    <Link
                        to='/'
                        className={styles.logoHolder}
                    >
                        <img src={logo} alt=""/>
                    </Link>


                    <div className={styles.navLink}>
                        <Link to='/contact'>
                            <span>Контакты</span>
                        </Link>

                        {
                            auth &&
                            <div ref={logoRef} style={{
                                marginLeft: '12px',
                                cursor: 'pointer',
                            }}
                                 onClick={() => setOpenProfile(!openProfile)}
                            >
                                <img src={profile?.avatar ? profile.avatar.url : avaPlaceholder}
                                     alt={`${auth.user}`}
                                     className={styles.userAvatar}/>
                            </div>
                        }

                        {
                            !auth &&
                            <Link to='/login' className={styles.loginButton}>
                                <span>Войти</span>
                            </Link>
                        }

                        {
                            openProfile &&
                            <div ref={dropdownRef}>
                                <DropDownProfile user={profile} setOpenProfile={setOpenProfile}/>
                            </div>
                        }
                    </div>

                </header>
            </nav>
        </>
    );
}

export default Navbar;
