import React, {useEffect, useState} from 'react';
import styles from './css/Specialist.module.css';
import {Link, useParams} from 'react-router-dom';
import arrowLeft from './icons/arrow-left.svg';
import eyeIcon from "../Card/eye.svg";
import Contacts from "./Contacts";
import BioContainer from "./BioContainer";
import ServicesContainer from "./ServicesContainer";
import Portfolio from "./Portfolio";
import specialistService from "../../api/specialistService";

function Specialist() {

    const {specialistId} = useParams();
    const [specialistDetails, setSpecialistDetails] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const response = specialistService.getSpecialistDetails(specialistId);
            response.then(jsonData => {
                setSpecialistDetails(jsonData);
            });
        };
        fetchData();
    }, []);

    // scroll to top on page load
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Empty dependency array ensures this effect only runs once

    return (
        <div className={styles.mainContainer}>
            <div className={styles.bannerSection}>

                {/*Banner header start*/}
                <div className={styles.bannerHeader}>
                    <Link to={'/'}>
                        <div className={styles.BackButton}>
                            <img src={arrowLeft} alt="Назад"/>
                            Назад
                        </div>
                    </Link>


                    <div className={styles.ViewsCount}>
                        <img src={eyeIcon} alt="Количество просмотров"/>
                        <span>{specialistDetails.viewsCount}</span>
                    </div>
                </div>
                {/*Banner header end*/}

                <div className={styles.InfoSection}>
                    <BioContainer specialist={specialistDetails.specialist}/>
                    <Contacts specialist={specialistDetails.specialist}/>
                    <div className={styles.AvatarContainer}>
                        {
                            specialistDetails.avatar &&
                            <img
                                src={`${specialistDetails.avatar.url}`}/>
                        }
                    </div>
                </div>

                <ServicesContainer servicePackages={specialistDetails.packages}/>
                <Portfolio projects={specialistDetails.livingSpaceProjects}/>
            </div>
        </div>
    );
}

export default Specialist;