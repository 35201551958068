import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import styles from "./ProfileBlock.module.css";
import {useForm} from "react-hook-form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {InputMask} from "@react-input/mask";
import {PhoneUtils} from "../../Util/phoneUtils";
import useAuth from "../../../hooks/useAuth";

const customStyles = {
    content: {
        width: '628px',
        height: 'auto',
        borderRadius: window.outerWidth <= 480 ? '0' : '16px',
        padding: window.outerWidth <= 480 ? '16px' : '32px',
        margin: window.outerWidth <= 480 ? '0px' : 'auto',
        inset: window.outerWidth <= 480 ? '0' : '40px',
        scrollbarWidth: 'thin'
    },
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
};

function ProfileModal({profile, modalIsOpen, setIsOpen}) {

    const axiosPrivate = useAxiosPrivate();
    const {auth, setAuth} = useAuth();

    const [cities, setCities] = useState([]);
    const [selectedCityId, setSelectedCityId] = useState(null);
    const [workStartDate, setWorkStartDate] = useState("");

    const {
        register, setValue, setError,
        handleSubmit, formState, watch
    }
        = useForm({
        mode: 'onChange'
    });
    const {errors} = formState;

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await axiosPrivate.get('api/partner/v1/cities')
                setCities(response.data);
                if (selectedCityId) {
                    setValue('city', selectedCityId);
                }
            } catch (err) {
                console.error(err);
            }
        }
        fetchCities();
    }, []);

    function afterOpenModal() {
        setValue('name', profile.name);
        setValue('surname', profile.surname);
        setValue('phone', PhoneUtils.formatPhoneNumber(profile.phone));
        setValue('whatsApp', PhoneUtils.formatPhoneNumber(profile.whatsappPhone));
        setValue('telegram', PhoneUtils.formatPhoneNumber(profile.telegramPhone));
        setValue('bio', profile.description);
        setSelectedCityId(profile.city.id);
        setWorkStartDate(profile.workStartDate);
    }

    const putProfile = async (data) => {
        const response
            = await axiosPrivate.put(`/api/partner/v1/profile`, data);
        return response.data;
    }

    function submit(data) {
        const response = putProfile(toProfileBody(data));

        response.then(data => {
            setAuth({...auth});
            closeModal();
        }).catch(error => {
            if (error.response) {
                alert(error.response.data.message);
            }
        });
    }

    const onCitySelected = (event) => {
        const selectedCity = cities.find(city => city.id === Number(event.target.value));
        setValue('city', selectedCity.id, {
            shouldValidate: true, // This will trigger validation after setting the value
        })
        setSelectedCityId(selectedCity.id);
    }

    const onWorkStartDateChange = (event) => {
        setWorkStartDate(event.target.value);
    }

    function toProfileBody(data) {
        return {
            name: data.name,
            surname: data.surname,
            phone: data.phone,
            whatsappPhone: data.whatsApp,
            telegramPhone: data.telegram,
            workStartDate: workStartDate,
            description: data.bio,
            cityId: selectedCityId
        }
    }

    return (
        <Modal isOpen={modalIsOpen}
               onAfterOpen={afterOpenModal}
               onRequestClose={closeModal}
               style={customStyles}
               appElement={document.getElementById('root')}
               contentLabel={'Редактировать профиль'}>
            <div className={styles.profileModalContainer}>
                <h2>
                    Редактировать профиль
                </h2>

                <form onSubmit={handleSubmit(submit)}>


                    <div className={styles.formControl}>
                        <label htmlFor="name">Имя</label>
                        <input id='name' type='text'
                               placeholder={'Введите имя'}
                               {...register('name', {
                                   required:
                                       'Имя не может быть пустой'
                               })} />
                        <p className={styles.error}> {errors.name?.message}</p>
                    </div>

                    <div className={styles.formControl}>
                        <label htmlFor="surname">
                            Фамилия
                        </label>
                        <input id='surname' type='text'
                               placeholder={'Введите фамилию'}
                               {...register('surname', {
                                   required:
                                       'Фамилия не может быть пустой'
                               })} />
                        <p className={styles.error}> {errors.name?.message}</p>
                    </div>

                    {/* Phone */}
                    <div className={styles.formControl}>
                        <label htmlFor="phone">
                            Номер телефона
                        </label>
                        <InputMask id='phone' placeholder={'Номер телефона'}
                                   mask="+7 (___) ___-__-__" replacement={{_: /\d/}}
                                   showMask={true}
                                   {...register('phone', {
                                       validate: {
                                           isNumbersCorrect: (value) =>
                                               PhoneUtils.isValidPhoneNumber(value) || 'Введите номер телефона полностью'
                                       }
                                   })} />
                        <p className={styles.error}> {errors.phone?.message}</p>
                    </div>

                    {/* WhatsApp */}
                    <div className={styles.formControl}>
                        <label htmlFor="whatsApp">
                            WhatsApp
                        </label>
                        <InputMask id='whatsApp' placeholder={'WhatsApp'}
                                   mask="+7 (___) ___-__-__" replacement={{_: /\d/}}
                                   showMask={true}
                                   {...register('whatsApp', {
                                       validate: {
                                           isNumbersCorrect: (value) =>
                                               PhoneUtils.isValidPhoneNumber(value) || 'Введите номер телефона полностью'
                                       }
                                   })} />
                        <p className={styles.error}> {errors.whatsApp?.message}</p>
                    </div>

                    {/* Telegram */}
                    <div className={styles.formControl}>
                        <label htmlFor="telegram">
                            Telegram
                        </label>
                        <InputMask id='telegram' placeholder={'Telegram'}
                                   mask="+7 (___) ___-__-__" replacement={{_: /\d/}}
                                   showMask={true}
                                   {...register('telegram', {
                                       validate: {
                                           isNumbersCorrect: (value) =>
                                               PhoneUtils.isValidPhoneNumber(value) || 'Введите номер телефона полностью'
                                       }
                                   })} />
                        <p className={styles.error}> {errors.telegram?.message}</p>
                    </div>

                    <div className={styles.formControl}>
                        <label htmlFor="city">
                            Город
                        </label>
                        <select id={'city'} {...register('city', {
                            required: 'Выберите город',
                        })} onChange={(event) => {
                            onCitySelected(event)
                        }}>
                            {cities.map((city) => {
                                return (
                                    <option key={city.id} value={city.id}>
                                        {city.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    {/* Experience */}

                    <div className={styles.formControl}>
                        <label htmlFor="workStartDate">
                            Дата начала работы
                        </label>
                        <input aria-label="Date" type="date" onFocus={(event) => event.target.showPicker()}
                               value={workStartDate} onChange={onWorkStartDateChange}/>
                        <p className={styles.error}> {errors.workStartDate?.message}</p>
                    </div>

                    {/* Bio */}
                    <div className={styles.formControl}>
                        <label htmlFor="bio">
                            Био
                        </label>
                        <textarea id='bio' placeholder={'О себе'}
                                  rows={5}
                                  {...register('bio', {
                                      required: false
                                  })}/>
                    </div>

                    <div style={{
                        float: 'right'
                    }}>
                        <button className={styles.addButton} type={'submit'}>
                            Редактировать
                        </button>
                    </div>

                </form>

            </div>

        </Modal>
    );
}

export default ProfileModal;
