import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import styles from "../css/ProjectsBlock.module.css";
import {useForm} from "react-hook-form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const customStyles = {
    content: {
        width: '628px',
        height: 'auto',
        borderRadius: window.outerWidth <= 480 ? '0' : '16px',
        padding: window.outerWidth <= 480 ? '16px' : '32px',
        margin: window.outerWidth <= 480 ? '0px' : 'auto',
        overflow: 'hidden',
        inset: window.outerWidth <= 480 ? '0' : '40px',
    },
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
};

function ServicePackageModal({
                                 servicePackage,
                                 modalIsOpen, setIsOpen,
                                 servicePackagesChanged, setServicePackagesChanged
                             }) {

    const [servicesList, setServicesList] = useState([]);
    const axiosPrivate = useAxiosPrivate();

    const {
        register, setValue, setError,
        handleSubmit, formState, watch
    }
        = useForm({
        mode: 'onChange'
    });
    const {serviceName} = watch();
    const {errors} = formState;

    function closeModal() {
        setIsOpen(false);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
        if (servicePackage) {
            setValue('name', servicePackage.name);
            setValue('price', servicePackage.price);
            setServicesList(servicePackage.services);
        } else {
            setValue('name', '');
            setValue('price', '');
            setServicesList([]);
        }
    }

    const deleteServicePackage = async () => {
        const userConfirmed = window.confirm("Вы уверены, что хотите удалить пакет услуг? Все данные будут потеряны.");
        if (!userConfirmed) {
            return;
        }

        await axiosPrivate.delete(`/api/partner/v1/service-packages/${servicePackage.id}`);
        setServicePackagesChanged(!servicePackagesChanged);
        closeModal();
    }

    const postServicePackage = async (data) => {
        const response = await axiosPrivate.post(`/api/partner/v1/service-packages`,
            buildServicePackage(data));
        return response.data;
    }

    const putServicePackage = async (data) => {
        const response
            = await axiosPrivate.put(`/api/partner/v1/service-packages/${servicePackage.id}`,
            buildServicePackage(data));
        return response.data;
    }

    const buildServicePackage = (data) => {
        return {
            name: data.name,
            services: servicesList,
            price: data.price
        };
    }

    function submit(data) {
        const response = servicePackage ? putServicePackage(data) : postServicePackage(data);

        response.then(data => {
            console.log(data);
            setServicePackagesChanged(!servicePackagesChanged);
            closeModal();
        }).catch(error => {
            if (error.response) {
                alert(error.response.data.message);
            }
        });
    }

    const addServiceToPackage = () => {
        if (!serviceName) {
            setError('serviceName', {
                type: 'manual',
                message: 'Название услуги не может быть пустым'
            });
            return;
        }

        const newService = serviceName.trim();

        // check whether servicesList contains serviceName
        if (servicesList.includes(newService)) {
            setError('serviceName', {
                type: 'manual',
                message: 'Услуга уже добавлена'
            });
            return;
        }

        setServicesList((prevServicesList) => [...prevServicesList, newService]);
        setValue('serviceName', '');
    }

    const deleteService = (service) => {
        setServicesList((prevServicesList) => prevServicesList.filter((s) => s !== service));
    }

    return (
        <Modal isOpen={modalIsOpen}
               onAfterOpen={afterOpenModal}
               onRequestClose={closeModal}
               style={customStyles}
               appElement={document.getElementById('root')}
               contentLabel={'Добавить проект'}>
            <div>
                <h2>
                    {
                        !servicePackage && 'Добавить пакет услуг'
                    }
                    {
                        servicePackage && 'Редактировать пакет услуг'
                    }
                </h2>

                <form onSubmit={handleSubmit(submit)}>

                    <div className={styles.formControl}>
                        <label htmlFor="name">Название</label>
                        <input id='name' type='text'
                               placeholder={'Введите название пакета услуг'}
                               {...register('name', {
                                   required:
                                       'Название не может быть пустым'
                               })} />
                        <p className={styles.error}> {errors.name?.message}</p>
                    </div>

                    <div className={styles.formControl}>
                        <label htmlFor="serviceName">Услуги</label>

                        <div className={styles.servicesList}>
                            {
                                servicesList.map((service, index) => {
                                    return (
                                        <div key={index}
                                             className={styles.service}>
                                            <h3>{service}</h3>

                                            <button className={styles.deleteServiceButton}
                                                    onClick={() => deleteService(service)}/>
                                        </div>
                                    );
                                })
                            }
                        </div>

                        <div className={styles.addServiceBlock}>
                            <input id='serviceName' type='text'
                                   placeholder={'Введите название услуги'}
                                   {...register('serviceName', {
                                       required: false
                                   })} />

                            <button onClick={addServiceToPackage} type={'button'}>
                                Добавить
                            </button>
                        </div>

                        <p className={styles.error}> {errors.serviceName?.message}</p>
                    </div>

                    <div className={styles.formControl}>
                        <label htmlFor="price">Стоимость</label>
                        <input id='price' type='number'
                               placeholder={'Введите стоимость'}
                               {...register('price', {
                                   required:
                                       'Стоимость не может быть пустой'
                               })} />
                        <p className={styles.error}> {errors.price?.message}</p>
                    </div>

                    {
                        !servicePackage &&
                        <button className={styles.addButton} type={'submit'}>
                            Создать
                        </button>
                    }

                    {
                        servicePackage &&
                        <div style={{
                            float: 'right'
                        }}>
                            <button className={styles.deleteButton}
                                    type={'button'}
                                    onClick={deleteServicePackage}>
                                Удалить
                            </button>

                            <button className={styles.addButton} type={'submit'}>
                                Редактировать
                            </button>
                        </div>
                    }
                </form>

            </div>

        </Modal>
    );
}

export default ServicePackageModal;
