import React from "react";
import styles from "./css/WorkDetails.module.css";
import Modal from "react-modal";
import closeIcon from "./icons/close.svg";
import PrevIcon from "./icons/PrevIcon";
import NextIcon from "./icons/NextIcon";

const customStyles = {
    content: {
        maxWidth: window.outerWidth <= 480 ? '100%' : '80%',
        width: '100%',
        borderRadius: window.outerWidth <= 480 ? '0' : '16px',
        padding: window.outerWidth <= 480 ? '16px   ' : '32px',
        margin: window.outerWidth <= 480 ? '0px' : 'auto',
        overflow: 'hidden',
        maxHeight: window.outerWidth <= 480 ? '100%' : '80%',
        inset: window.outerWidth <= 480 ? '0' : '40px',
    },
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
};

function WorkDetails({project, modalIsOpen, setIsOpen}) {

    const [activeImage, setActiveImage] = React.useState(0);
    const [activeRoomType, setActiveRoomType] = React.useState(0);
    const rooms = project.rooms;

    // Check if myObject exists before accessing its properties
    if (!rooms) {
        return <div style={{display: 'none'}}>Loading...</div>;
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const nextImage = () => {
        if (activeImage >= rooms[activeRoomType]?.images.length - 1) {
            setActiveImage(0);
        } else {
            setActiveImage(activeImage + 1);
        }
    }

    const prevImage = () => {
        if (activeImage <= 0) {
            setActiveImage(rooms[activeRoomType].images.length - 1);
        } else {
            setActiveImage(activeImage - 1);
        }
    }

    const changeActiveRoomType = (index) => {
        setActiveRoomType(index);
        setActiveImage(0);
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel={project.name}
        >
            <div className={styles.MainContainer}>
                <div className={styles.Header}>
                    <h1>
                        {project.name}
                    </h1>

                    <h2>
                        {project.squareMeters} кв.м
                    </h2>

                    <img src={closeIcon} alt="Close button"
                         className={styles.closeButton}
                         onClick={closeModal}/>
                </div>

                <div className={styles.RoomTypes}>
                    {
                        rooms.map((roomType, index) => {
                            return (
                                <div key={roomType.id}
                                     className={`${styles.RoomType} ${activeRoomType === index ? styles.active : styles.inactive}`}
                                     onClick={() => {
                                         changeActiveRoomType(index);
                                     }}>
                                    <h3>{roomType.roomType.name}</h3>
                                </div>
                            )
                        })
                    }
                </div>

                {/* Pictures for desktop START */}
                <div className={styles.WorkImage}>
                    <PrevIcon onClick={prevImage}/>

                    <img
                        className={styles.mainImage}
                        src={`${rooms[activeRoomType]?.images[activeImage]?.url}`}
                        alt={rooms[activeRoomType]?.roomType?.name}/>

                    <NextIcon onClick={nextImage}/>
                </div>

                <div className={styles.CounterContainer}>
                    {activeImage + 1}/{rooms[activeRoomType]?.images.length}
                </div>
                {/* Pictures for desktop END */}

                {/* Pictures for mobile START */}
                <div className={styles.MobileVersionImages}>
                    {rooms[activeRoomType]?.images.map((item, index) => (
                        <img
                            key={index}
                            className={styles.mainImage}
                            src={`${item.url}`}
                            alt={rooms[activeRoomType].roomType.name}/>
                    ))}
                </div>
                {/* Pictures for mobile END */}

            </div>
        </Modal>
    );
}

export default WorkDetails;


